<template>
  <div>
      联系我们
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
  data() {
    return {
    }
  },
  created() {

  },
  methods: {
  }
}
</script>